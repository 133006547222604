import React, { createContext, useState, useEffect, ReactNode } from 'react';
import useDebounce from '../hooks/useDebounce';

interface Props {
  children: ReactNode;
}

interface WindowContextProps {
  getSize: (baseWidth: number) => string;
}

export const WindowContext = createContext<WindowContextProps>({
  getSize: () => '',
});

const getInitialWidth = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth;
  }
  return 1920;
};

export const WindowProvider = ({ children }: Props) => {
  const [width, setWidth] = useState<number>(getInitialWidth);

  const windowWidth = useDebounce(width, 50);

  const getSize = (baseSize: number) => {
    return `${baseSize * (windowWidth / 1920)}px`;
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <WindowContext.Provider value={{ getSize }}>{children}</WindowContext.Provider>;
};
