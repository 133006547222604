import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import { WindowContext } from '../../providers/WindowProvider';
import { LightTheme } from '../../theme/theme';

interface Props {
  children?: ReactNode;
}

export const Theme = ({ children }: Props) => {
  const { getSize } = useContext(WindowContext);

  const [theme, setTheme] = useState<DefaultTheme>(LightTheme);

  useEffect(() => {
    setTheme((prevState) => ({
      ...prevState,
      typography: {
        desktop: {
          heroSecton: {
            fontFamily: '"Neue Plak Extended", sans-serif',
            fontWeight: 400,
            fontSize: getSize(130),
            lineHeight: getSize(156),
          },
          h1: {
            fontFamily: '"Neue Plak Extended", sans-serif',
            fontWeight: 400,
            fontSize: getSize(121),
            lineHeight: getSize(145.2),
          },
          h2: {
            fontFamily: '"Neue Plak Extended Semi", sans-serif',
            fontWeight: 400,
            fontSize: getSize(48),
            lineHeight: getSize(62.4),
          },
          h3: {
            fontFamily: '"Neue Plak Extended Semi", sans-serif',
            fontWeight: 400,
            fontSize: getSize(36),
            lineHeight: getSize(46.6),
          },
          h4: {
            fontFamily: '"Neue Plak Extended Semi", sans-serif',
            fontWeight: 400,
            fontSize: getSize(30),
            lineHeight: getSize(39),
          },
          h5: {
            fontFamily: '"Neue Plak Extended Semi", sans-serif',
            fontWeight: 400,
            fontSize: getSize(24),
            lineHeight: getSize(31.2),
          },
          h6: {
            fontFamily: '"Neue Plak Extended Semi", sans-serif',
            fontWeight: 400,
            fontSize: getSize(18),
            lineHeight: getSize(23.4),
          },
          gilroyButton: {
            fontFamily: '"Gilroy Semibold", sans-serif',
            fontWeight: 600,
            fontSize: getSize(20),
            lineHeight: getSize(23.4),
            letterSpacing: '1px',
          },
          gilroyText: {
            fontFamily: '"Gilroy Semibold", sans-serif',
            fontWeight: 600,
            fontSize: getSize(20),
            lineHeight: getSize(30),
            letterSpacing: '1px',
          },
          largeBody: {
            fontFamily: '"Gilroy", sans-serif',
            fontWeight: 400,
            fontSize: getSize(24),
            lineHeight: getSize(31.2),
          },
          regularBody: {
            fontFamily: '"Gilroy", sans-serif',
            fontWeight: 400,
            fontSize: getSize(20),
            lineHeight: getSize(30),
            letterSpacing: '1px',
          },
          smallBody: {
            fontFamily: '"Gilroy", sans-serif',
            fontWeight: 500,
            fontSize: getSize(16),
            lineHeight: getSize(20.8),
            fontStyle: 'normal',
          },
        },
        mobile: {
          h1: {
            fontFamily: '"Neue Plak Extended", sans-serif',
            fontWeight: 400,
            fontSize: '54px',
            lineHeight: '64.8px',
          },
          h2: {
            fontFamily: '"Neue Plak Extended Semi", sans-serif',
            fontWeight: 400,
            fontSize: '36px',
            lineHeight: '46.8px',
          },
          h3: {
            fontFamily: '"Neue Plak Extended Regular", sans-serif',
            fontWeight: 400,
            fontSize: '32px',
            lineHeight: '41.6px',
          },
          h4: {
            fontFamily: '"Gilroy Semibold", sans-serif',
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '31.2px',
          },
          regularBody: {
            fontFamily: '"Gilroy Medium", sans-serif',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '18.2px',
          },
          middleBody: {
            fontFamily: '"Gilroy Medium", sans-serif',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '20.8px',
          },
          largeBody: {
            fontFamily: '"Gilroy Medium", sans-serif',
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '23.4px',
          },
        },
      },
    }));
  }, [getSize]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
